<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="3"
            style="background-color: #00b2f9"
          >
            <v-list>
              <v-list-item
                v-for="(item, i) in chats"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            cols="9"
            style="background-color: #7d8e94"
          >
            v
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    name: 'ContactManager',
    data: () => ({
      selectedItem: 1,
      chats: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
    }),
  }
</script>
